import {
  CRMDataRow,
  defaultAnchorEl,
  INAnchorsEl,
} from "@/pages/Upselling/v2/Crm/components/crm-upselling.types";
import React, {
  createRef,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import ViewCrmDialog from "@/pages/Upselling/v2/Crm/components/ViewCrmDialog";
import PresenceUserDialog, {
  IForwardRef as IPresenceUserDialogRef,
} from "@/pages/Users/components/PresenceUserDialog";
import { IRef as IDialogNewStatusToBeConvertedRef } from "@/components/Crm/DialogNewStatusToBeConverted";
import {
  defaultButtonCup,
  defaultConfirmDialog,
  defaultDialogsEl,
  defaultFormMeetingDone,
  INButtonCup,
  INConfirmDialog,
  INDialogsEl,
} from "@/pages/Upselling/v2/Crm/interfacesAndDefaults";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import { IAddInterestedEventDialog as IEventDialog } from "@/components/Crm/AddInterestedEventDialog";
import { IFormMeetingDone } from "@/components/Crm/MeetingComponent";

/**
 * This state/context handles the toggles for all dialogs on the crm upselling page
 */

type CrmDialogsState = {
  crmDialogState: {
    viewCrmDialog: { data: CRMDataRow; open: boolean };
    setViewCrmDialog: Dispatch<
      SetStateAction<{ data: CRMDataRow; open: boolean }>
    >;
  };
  confirmDialogState: {
    confirmDialogData: INConfirmDialog;
    setConfirmDialogData: Dispatch<SetStateAction<INConfirmDialog>>;
  };
  dialogsElState: {
    dialogsEl: INDialogsEl;
    setDialogsEl: Dispatch<SetStateAction<INDialogsEl>>;
  };
  anchorsElState: {
    anchorsEl: INAnchorsEl;
    setAnchorsEl: Dispatch<SetStateAction<INAnchorsEl>>;
  };
  PresenceUserDialogRef: React.RefObject<IPresenceUserDialogRef>;
  dialogNewStatusToBeConvertedRef: React.RefObject<IDialogNewStatusToBeConvertedRef>;
  eventRef: React.RefObject<IEventDialog>;
  hideColumnsState: {
    hideColumns: boolean;
    setHideColumns: Dispatch<SetStateAction<boolean>>;
  };
};

const CrmDialogsContext = React.createContext<CrmDialogsState | undefined>(
  undefined
);

const keyLocalStorageHideColumnsCrm = "hide-columns-crm";

export const CrmDialogsContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [viewCrmDialog, setViewCrmDialog] = useState<{
    data: CRMDataRow;
    open: boolean;
  }>({ open: false, data: null });
  const PresenceUserDialogRef = createRef<IPresenceUserDialogRef>();
  const dialogNewStatusToBeConvertedRef =
    createRef<IDialogNewStatusToBeConvertedRef>();
  const [confirmDialogData, setConfirmDialogData] =
    useState<INConfirmDialog>(defaultConfirmDialog);
  const [dialogsEl, setDialogsEl] = useState<INDialogsEl>(defaultDialogsEl);
  const eventRef = createRef<IEventDialog>();
  const [anchorsEl, setAnchorsEl] = useState<INAnchorsEl>(defaultAnchorEl);
  const [hideColumns, setHideColumns] = useState<boolean>(
    Boolean(Number(localStorage.getItem(keyLocalStorageHideColumnsCrm))) ??
      false
  );

  const value: CrmDialogsState = {
    crmDialogState: {
      viewCrmDialog,
      setViewCrmDialog,
    },
    confirmDialogState: {
      confirmDialogData,
      setConfirmDialogData,
    },
    dialogsElState: {
      dialogsEl,
      setDialogsEl,
    },
    anchorsElState: {
      anchorsEl,
      setAnchorsEl,
    },
    PresenceUserDialogRef,
    dialogNewStatusToBeConvertedRef,
    eventRef,
    hideColumnsState: {
      hideColumns,
      setHideColumns,
    },
  };

  useEffect(() => {
    localStorage.setItem(
      keyLocalStorageHideColumnsCrm,
      String(Number(hideColumns))
    );
  }, [hideColumns]);

  return (
    <CrmDialogsContext.Provider value={value}>
      {children}
      <PresenceUserDialog ref={PresenceUserDialogRef} />
      <ViewCrmDialog
        user={viewCrmDialog?.data}
        open={Boolean(viewCrmDialog?.open)}
        onClose={() => setViewCrmDialog((p: any) => ({ ...p, open: false }))}
      />
      <ConfirmationDialog
        open={confirmDialogData.open}
        title={confirmDialogData.title}
        onClose={() => confirmDialogData?.onDisagree?.()}
        onConfirm={() => confirmDialogData?.onAgree?.()}
      >
        {confirmDialogData.text}
      </ConfirmationDialog>
    </CrmDialogsContext.Provider>
  );
};

export function useCrmDialogsContext() {
  const context = React.useContext(CrmDialogsContext);

  if (!context)
    throw new Error(
      "useCrmDialogsContext must be used within an CrmDialogsContextProvider"
    );

  return context;
}
