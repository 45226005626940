import SearchToolbar from "@/components/Toolbars/SearchToolbar";
import { Box, Button } from "@mui/material";

const ExercisesToolbar = ({ filters, setFilters }) => {
  return (
    <Box
      id="filter_bar"
      className="flex gap-4"
    >
      {Boolean(setFilters) && (
        <Button
          variant="outlined"
          onClick={() => {
            setFilters?.((f: any) => {
              if (!filters?.version) {
                f?.set("version", "pot");
              } else {
                f?.delete("version");
              }
              return f;
            });
          }}
        >
          Change version
        </Button>
      )}

      <SearchToolbar {...{ filters, setFilters }} />
    </Box>
  );
};

export default ExercisesToolbar;
