import { useState, useEffect, useMemo, createRef } from "react";
import { euro } from "@/components/useHelpers";
import useAuth from '@/hooks/useAuth';
import { getTableStyling } from "@/providers/Colors";
import { IDialpadCallModal } from "@/components/Dialpad/interface";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import {
  Box,
  MenuItem,
  useTheme,
  MenuList,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  PopoverProduct,
  IAnchorElProduct,
  RenderCellProduct,
  valueGetterProduct,
  defaultAnchorElProduct,
  PopoverSetProductFromPayment,
} from "@/components/User/PopoverProduct";
import CreateUser, { Products } from "@/pages/Users/components/CreateUser";
import InvoiceUserDialog, {
  IForwardRef as InvoiceUserDialogRef,
} from "@/pages/Users/components/InvoiceUserDialog";
import CreateInvoicePaymentInDialog, {
  IRef as IRefCreateInvoicePaymentInDialog,
} from "@/components/PaymentsIn/CreateInvoicePaymentInDialog";
import useAPI from "@/api/useAPI";
import mailIcon from "@/assets/icons/mail.svg";
import callIcon from "@/assets/icons/call.svg";
import editIcon from "@/assets/icons/edit.svg";
import chatIcon from "@/assets/icons/chat.svg";
import Dropdown from "@/components/_Common/Dropdown";
import EduBadge from "@/components/_Common/EduBadge";
import MyTooltip from "@/components/MyTooltip";
import FlagRender from "@/components/FlagRender";
import invoiceIcon from "@/assets/icons/invoice.svg";
import commentsIcon from "@/assets/icons/comments.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useProductApi from "@/api/useProductApi";
import PaymentInIcon from "@/assets/icons/payment-incoming.svg";
import UsersListNotes from "./UsersListNotes";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import ToolbarUsersList from "./ToolbarUsersList";
import CustomPagination from "@/components/_Common/CustomPagination";
import DialpadCallModal from "@/components/Dialpad/DialpadSingleCallV2/DialpadSingleCall";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import useConvertStartMonthEn from "@/hooks/useConvertStartMonthEn";

const CHECKOUT_BASE = import.meta.env.VITE_CHECKOUT_BASE as string;

const BASE_REG_LINK = "https://academy.edusogno.com/user/registration/";
const BASE_PAYMENT_LINK = CHECKOUT_BASE + "existing-user/";

export const ONBOARDING_VALUES_NEW_USERS = ["YES", "NO", "Scheduled"];

interface ICopyLink {
  tokenUser: string;
  tokenProduct?: string;
}

const copyLink = (data: ICopyLink) => {
  return !data?.tokenProduct
    ? BASE_REG_LINK + data.tokenUser
    : BASE_PAYMENT_LINK + data.tokenProduct + "/" + data.tokenUser;
};

const IndexUsersList = ({
  users,
  page,
  filters,
  setPage,
  perPage,
  cacheKey,
  isLoading,
  sortModel,
  userAdmin,
  pagination,
  setFilters,
  onSortModelChange,
}) => {
  const theme = useTheme();
  const { copyToClipboard, snackHandler } = useAuth();

  const { data: products } = useProductApi().callGetProducts();

  const [open, setOpen] = useState<boolean>(false);
  const [rows, setRows] = useState<Array<any>>([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [selectedRows, setSelectRows] = useState([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [userSelected, setUserSelected] = useState<{} | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<any>(null);
  const [callStarted, setCallStarted] = useState(false);
  const [anchorElProduct, setAnchorElProduct] = useState<IAnchorElProduct>(
    defaultAnchorElProduct
  );
  const InvoiceUserDialogRef = createRef<InvoiceUserDialogRef>();
  const [massCallStarted, setMassCallStarted] = useState(false);
  const [targetCaller, setTargetCaller] = useState({});

  const dialpadCallRef = createRef<IDialpadCallModal>();

  const [
    anchorElPopoverSetProductFromPayment,
    setAnchorElPopoverSetProductFromPayment,
  ] = useState<IAnchorElProduct>(defaultAnchorElProduct);

  const invoiceDialogRef = createRef<IRefCreateInvoicePaymentInDialog>();

  const selectedUser = users?.filter(
    (user: { token: string }) => user.token === selectedUserId
  );

  const productsData = useMemo(() => {
    const tempProducts =
      products?.products?.map((product: any) => ({
        id: product?.id,
        name: product?.course_info?.course_name || product?.product_tag,
      })) || [];

    return [
      ...new Map(tempProducts.map((item: any) => [item["id"], item])).values(),
    ] as Products[];
  }, [products]);

  // const pathsData = useMemo(() => {
  //   const tempProducts =
  //     products?.products?.map((product: any) => {
  //       return {
  //         id: product?.percorsi?.id,
  //         name: product?.percorsi?.nome_percorso,
  //       };
  //     }) || [];

  //   return [
  //     ...new Map(tempProducts.map((item) => [item["id"], item])).values(),
  //   ] as Products[];
  // }, [products]);
  const handleCallEnded = () => {
    setCallStarted(false);
    setMassCallStarted(false);
  };

  const handleDialpadCall = (row: any) => {
    setTargetCaller(row);
    if (dialpadCallRef.current) {
      dialpadCallRef.current.openModal();
    }
  };

  const handleMassCall = (row: any) => {
    setMassCallStarted(true);
  };

  const handleOpenDialog = (user: any) => {
    setOpenDialog(true);
    setUserSelected(user);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setUserSelected(null);
  };

  const handleRowSelectionChange = (newSelection) => {
    setSelectionModel(newSelection);
    setSelectRows(
      newSelection.map((uuid) => rows.find((row) => row.uuid === uuid))
    );
  };

  const handleUpdate = async (token: string, values: object) => {
    const res = await useAPI(
      `/v1/listWithSecondPayment/${token}/update`,
      values
    );

    if (!res?.status) {
      snackHandler(res?.message ?? "Failed update!", "error");
      return false;
    }

    snackHandler("Success update!");
    return true;
  };

  const columns: GridColDef[] = [
    {
      field: "lang",
      headerName: "",
      align: "center",
      maxWidth: 75,
      renderCell: ({ row }) => <FlagRender flag={row?.lang} />,
    },
    {
      field: "fullName",
      headerName: "Name",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => `${row?.first_name} ${row?.last_name}`,
      renderCell: ({ row }) => (
        <MyTooltip>{`${row?.first_name} ${row?.last_name}`}</MyTooltip>
      ),
    },
    {
      field: "start_month",
      headerName: "Start month",
      minWidth: 170,
      flex: 0.5,
      valueGetter: ({ row }) => useConvertStartMonthEn(row?.start_month) || "-",
    },
    {
      field: "product",
      headerName: "Product",
      minWidth: 220,
      flex: 1,
      sortable: false,
      valueGetter: ({ row }) => valueGetterProduct(row),
      renderCell: ({ row }) => (
        <RenderCellProduct
          {...{
            row,
            setAnchorElProduct,
            setAnchorElPopoverSetProductFromPayment,
          }}
        />
      ),
    },
    {
      field: "attendance",
      headerName: "#Attendance",
      minWidth: 120,
      flex: 0.5,
      sortable: false,
      // valueGetter: ({ row }) => row?.products_selected?.at(-1)?.price || "-",
      renderCell: ({ row }) => {
        return <Box>{row?.attendance}</Box>;
      },
    },
    {
      field: "sold_by",
      headerName: "Sold by",
      minWidth: 150,
      flex: 0.3,
      sortable: false,
      editable: false,
      hideable: false,
      filterable: false,
      disableExport: true,
      valueGetter: ({ row }) => row?.sold_by || "-",
      renderCell: ({ row }) => <MyTooltip>{row?.sold_by}</MyTooltip>,
    },
    {
      field: "english_level",
      headerName: "English level",
      minWidth: 120,
      flex: 0.5,
      valueGetter: ({ row }) => row?.english_level,
    },
    {
      field: "ltv",
      headerName: "LTV",
      minWidth: 100,
      flex: 0.3,
      valueGetter: ({ row }) =>
        euro(
          row?.payments?.reduce(
            (acc: number, payment: any) => acc + (payment?.ltv || 0),
            0
          )
        ),
    },
    {
      field: "comment",
      headerName: "Comment",
      minWidth: 100,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        const latestComment =
          row?.note?.length > 0 ? row.note[row.note.length - 1] : "No comments";

        return (
          <Box
            color="primary"
            display="flex"
            alignItems="center"
            onClick={() => {
              setOpen(true);
              setSelectedUserId(row.token);
            }}
            style={{ cursor: "pointer" }}
          >
            {latestComment?.content ? (
              <Box>{latestComment.content}</Box>
            ) : (
              <ListItemIcon>
                <img
                  src={commentsIcon}
                  alt="comments icon"
                  className="icon"
                />
              </ListItemIcon>
            )}
          </Box>
        );
      },
    },
    // {
    //   field: "onboarding",
    //   headerName: "Onboarding",
    //   minWidth: 180,
    //   flex: 0.5,
    //   editable: false,
    //   hideable: false,
    //   sortable: false,
    //   filterable: false,
    //   disableExport: true,
    //   valueGetter: ({ row }) => row?.settings?.onboarding?.status ?? "NO",
    //   renderCell: ({ row }) => (
    //     <TextField
    //       select
    //       fullWidth
    //       value={row?.settings?.onboarding?.status ?? "NO"}
    //       onChange={async ({ target: { value } }) => {
    //         let s: any = row?.settings ?? {};

    //         if (!s?.onboarding) {
    //           s.onboarding = { status: value };
    //         } else {
    //           s.onboarding.status = value;
    //         }

    //         const res = await handleUpdate(row?.token, { settings: s });

    //         if (res) {
    //           setRows((p) => {
    //             const t = [...p];
    //             const index = t.findIndex((r) => r.token == row?.token);
    //             t[index].settings = s;
    //             return t;
    //           });
    //         }
    //       }}
    //     >
    //       {ONBOARDING_VALUES_NEW_USERS?.map((o, i) => (
    //         <MenuItem
    //           key={o + i}
    //           value={o}
    //         >
    //           {o}
    //         </MenuItem>
    //       ))}
    //     </TextField>
    //   ),
    // },
    {
      field: "actions",
      headerName: "",
      width: 70,
      sortable: false,
      hideable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <EduBadge
          color="primary"
          variant="dot"
          invisible={true}
          badgeContent={row?.note?.length}
        >
          <Dropdown
            text={<MoreVertIcon />}
            content={
              <MenuList>
                <MenuItem
                  title="Copy email"
                  color="primary"
                  onClick={() => copyToClipboard(row.email)}
                >
                  <ListItemIcon>
                    <img
                      src={mailIcon}
                      className="icon"
                    />
                  </ListItemIcon>
                  <ListItemText>Copy email</ListItemText>
                </MenuItem>

                <MenuItem
                  title="Send WhatsApp message"
                  onClick={() =>
                    window.open(
                      `https://api.whatsapp.com/send?phone=${row.phone}"&text=Ciao ${row?.first_name} ${row?.last_name}`
                    )
                  }
                >
                  <ListItemIcon>
                    <img
                      src={chatIcon}
                      className="icon"
                    />
                  </ListItemIcon>
                  <ListItemText>WhatsApp</ListItemText>
                </MenuItem>

                <MenuItem
                  color="primary"
                  title="Invoices"
                  onClick={() => InvoiceUserDialogRef.current?.open(row.token)}
                >
                  <ListItemIcon>
                    <img
                      src={invoiceIcon}
                      className="icon"
                    />
                  </ListItemIcon>
                  <ListItemText>Invoices</ListItemText>
                </MenuItem>

                <MenuItem
                  title="Add payment"
                  color="primary"
                  onClick={() =>
                    invoiceDialogRef?.current?.open({
                      user: row,
                      products: products.products,
                    })
                  }
                >
                  <ListItemIcon>
                    <img
                      width="16px"
                      src={PaymentInIcon}
                      className="icon"
                    />
                  </ListItemIcon>
                  <ListItemText>Add payment</ListItemText>
                </MenuItem>
                <MenuItem
                  title="Call user"
                  color="primary"
                  onClick={() => handleDialpadCall(row)}
                >
                  <ListItemIcon>
                    <img
                      width="16px"
                      src={callIcon}
                      className="icon"
                    />
                  </ListItemIcon>
                  <ListItemText>Dialpad Call</ListItemText>
                </MenuItem>

                <MenuItem
                  title="Edit user"
                  color="primary"
                  onClick={() => handleOpenDialog(row)}
                >
                  <ListItemIcon>
                    <img
                      src={editIcon}
                      className="icon"
                    />
                  </ListItemIcon>
                  <ListItemText>Edit user</ListItemText>
                </MenuItem>

                <MenuItem
                  title="Copy link for registration"
                  onClick={() =>
                    copyToClipboard(copyLink({ tokenUser: row.token }))
                  }
                >
                  <ListItemIcon>
                    <InsertLinkIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Copy link for registration</ListItemText>
                </MenuItem>

                {Boolean(row?.ini_payment?.product_token) && (
                  <MenuItem
                    title="Copy link for payment"
                    onClick={() => {
                      copyToClipboard(
                        copyLink({
                          tokenUser: row.token,
                          tokenProduct: row?.ini_payment?.product_token,
                        })
                      );
                    }}
                  >
                    <ListItemIcon>
                      <CurrencyExchangeIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Copy link for ini payment</ListItemText>
                  </MenuItem>
                )}
              </MenuList>
            }
          />
        </EduBadge>
      ),
    },
  ];

  useEffect(() => {
    if (users) setRows(users);
  }, [users]);

  return (
    <>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableVirtualization
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={rows}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        getRowId={(row) => row.uuid}
        sortModel={sortModel}
        sortingMode="server"
        onSortModelChange={onSortModelChange}
        components={{
          Toolbar: ToolbarUsersList,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
        componentsProps={{
          toolbar: {
            filters,
            setPage,
            handleMassCall,
            selectedRows,
            // pathsData,
            setFilters,
            productsData,
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
          pagination: { page, setPage, pagination },
        }}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        checkboxSelection
        onSelectionModelChange={(newSelection) =>
          handleRowSelectionChange(newSelection)
        }
        selectionModel={selectionModel}
      />

      <UsersListNotes
        open={open}
        setOpen={setOpen}
        cacheKey={cacheKey}
        userAdmin={userAdmin}
        selectedUser={selectedUser && selectedUser[0]}
      />

      {Boolean(anchorElProduct?.element) && (
        <PopoverProduct
          anchorElProduct={anchorElProduct}
          setAnchorElProduct={setAnchorElProduct}
        />
      )}

      {Boolean(anchorElPopoverSetProductFromPayment?.element) && (
        <PopoverSetProductFromPayment
          anchorElProduct={anchorElPopoverSetProductFromPayment}
          setAnchorElProduct={setAnchorElPopoverSetProductFromPayment}
        />
      )}

      <CreateUser
        open={openDialog}
        user={userSelected}
        cacheKey={cacheKey}
        products={productsData}
        handleClose={handleCloseDialog}
      />

      <CreateInvoicePaymentInDialog ref={invoiceDialogRef} />

      <DialpadCallModal
        ref={dialpadCallRef}
        row={targetCaller}
      />

      <InvoiceUserDialog ref={InvoiceUserDialogRef} />
    </>
  );
};

export default IndexUsersList;
