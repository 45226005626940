import { Box, Typography, useTheme } from "@mui/material";

type Props = {
  user: any;
};

const Comments = (props: Props) => {
  const { user } = props;
  const theme = useTheme();
  const data = user?.problematic_users?.updates;

  return (
    <>
      {data?.map(
        (e: any, i) =>
          e.status != "no reply" && (
            <div
              key={i}
              className="grid  mb-3 p-3"
            >
              <div className="mb-2 flex justify-between items-center">
                <Typography
                  color={"primary"}
                  fontSize="10px"
                >
                  {e?.admin} wrote on {e?.date}
                </Typography>
              </div>

              <Box
                className="flex flex-col border rounded mt-1"
                sx={{
                  borderColor: theme.palette.primary.main,
                  color: theme.palette.primary.main,
                }}
              >
                <Typography className="p-2">Issue: {e?.issue}</Typography>
                <Typography className="p-2">Solution: {e?.solution}</Typography>
              </Box>
            </div>
          )
      )}
    </>
  );
};

export default Comments;
