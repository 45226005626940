import deleteIcon from "@/assets/icons/delete.svg";
import CustomPagination from "@/components/_Common/CustomPagination";
import ConfirmationDialog from "@/components/ConfirmationDialog";
import useScreen from "@/hooks/useScreen";
import { getTableStyling } from "@/providers/Colors";
import { Button, useTheme } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ExercisesToolbar from "./components/ExercisesToolbar";

const getIeltsScore = (score: number) => {
  let ielts = {
    "39-40": 9,
    "37-38": 8.5,
    "35-36": 8,
    "32-34": 7.5,
    "30-31": 7,
    "26-29": 6.5,
    "23-25": 6,
    "18-22": 5.5,
    "16-17": 5,
    "13-15": 4.5,
    "11-12": 4,
    "0-10": 0,
  };

  for (let [range, value] of Object.entries(ielts)) {
    let rangeValues = range.split("-");
    if (score >= Number(rangeValues[0]) && score <= Number(rangeValues[1])) {
      return value;
    }
  }
};

const AllExercises = ({
  id,
  page,
  idUser,
  tokenEx,
  setPage,
  filters,
  setIdUser,
  exercises,
  isLoading,
  sortModel,
  setTokenEx,
  pagination,
  setFilters,
  onSortModelChange,
  openConfirmDialog,
  updateDeleteExercise,
  setOpenConfirmDialog,
  handleDeleteExercise,
}) => {
  const theme = useTheme();
  const { perPage } = useScreen();

  const columns: GridColDef[] = [
    {
      field: "name_student",
      headerName: "Student name",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "test_name",
      headerName: "Exercise name",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => row?.test_name || "-",
    },
    {
      field: "data",
      headerName: "Completion date",
      minWidth: 150,
      flex: 1,
      valueGetter: ({ row }) => row?.data || "-",
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 90,
      flex: 0.5,
      renderCell: ({ row }) => row?.type || "-",
    },
    {
      field: "user_score",
      headerName: "Score",
      minWidth: 70,
      flex: 0.5,
      sortable: false,
      valueGetter: ({ row }) => {
        if ([undefined, null].includes(row?.user_score)) return "-";

        if (row?.type === "Writing" || row?.type === "Speaking") {
          return row?.user_score;
        }

        const res =
          row?.version == "old"
            ? getIeltsScore(Number(row?.user_score.split("/")[0]))
            : row?.user_score;

        return res;
      },
    },
    {
      field: "actions",
      headerName: "",
      width: 70,
      hideable: false,
      sortable: false,
      editable: false,
      filterable: false,
      disableExport: true,
      renderCell: ({ row }) => (
        <Button
          onClick={() => {
            setOpenConfirmDialog((p: boolean) => !p);
            setTokenEx(row?.version == "pot" ? row?.id : row?.token_ex);
            setIdUser(row?.user_id);
          }}
        >
          <img
            src={deleteIcon}
            className="icon no-margin"
          />
        </Button>
      ),
    },
  ];

  return (
    <>
      <DataGrid
        autoHeight
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableSelectionOnClick
        sx={getTableStyling(theme)}
        rows={exercises}
        columns={columns}
        loading={isLoading}
        pageSize={perPage}
        sortModel={sortModel}
        sortingMode="server"
        componentsProps={{
          toolbar: {
            filters,
            setFilters,
          },
          pagination: { page, setPage, pagination },
        }}
        onSortModelChange={onSortModelChange}
        rowsPerPageOptions={[perPage]}
        experimentalFeatures={{ newEditingApi: false }}
        components={{
          Toolbar: ExercisesToolbar,
          Pagination: ({ page, setPage, pagination }) => (
            <CustomPagination
              page={page - 1}
              count={pagination?.total || 0}
              rowsPerPage={perPage}
              onPageChange={(_, newPage) => setPage(newPage + 1)}
            />
          ),
        }}
      />

      <ConfirmationDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={() => {
          if (filters?.version == "pot") {
            handleDeleteExercise();
          } else {
            updateDeleteExercise.mutate({
              tk: id,
              token_user: idUser,
              token_ex: tokenEx,
            });
          }
        }}
      >
        Do you want to delete?
      </ConfirmationDialog>
    </>
  );
};

export default AllExercises;
