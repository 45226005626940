import flags from "./flags";
import classNames from "classnames";

type Props = {
  flag: string;
  className?: string;
};

const getFlagEmoji = (countryCode: string) => {
  const codePoints = countryCode?.toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0))
  return String.fromCodePoint(...codePoints)
}

const FlagRender = (props: Props) => {
  const { flag, className, ...rest } = props;

  return (
    flags[flag] ? (
      <img
        alt={flag}
        src={flags[flag]}
        className={classNames({ "p-3": !className }, className)}
        {...rest}
      />
    ) : (
      <span>{getFlagEmoji(flag ?? '')}</span>
    )
  )
};

export default FlagRender;
