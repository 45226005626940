import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { queryClient } from "@/main";
import { LoadingButton } from "@mui/lab";
import { CALENDARS } from "@/providers/AuthProvider";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { Box, Drawer, MenuItem, TextField } from "@mui/material";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import useAPI from "@/api/useAPI";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import useCrmApi from "@/api/useCrmApi";
import LANGUAGES from "@/components/assets/LANGUAGES";
import PhoneField from "../_Common/Forms/PhoneField";
import CalendarEvents from "@/components/CalendarEvents/";
import useSessionStorage from "@/hooks/useSessionStorage";
import useAuth from "@/hooks/useAuth";

export interface IForwardRef {
  open: () => void;
}

interface IProps {
  ref: HTMLButtonElement;
}

interface INForm {
  first_name?: string;
  from?: string;
  lang?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  origin?: string;
  tutoring: number;
}

const CreateNewUserDialog = forwardRef<IForwardRef, IProps>((_, ref) => {
  const { userAdmin, isAdmission, snackHandler } = useAuth();
  const { keyCache, callUpdateCrm } = useCrmApi();
  const getParams = useSessionStorage<object>(keyCache)[0];

  const [user, setUser] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [form, setForm] = useState<INForm>({ tutoring: Number(isAdmission) });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);

  const handleClose = () => {
    setIsLoading(false);
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
  }));

  const admin = userAdmin.user.first_name + " " + userAdmin.user.last_name;

  const calendars =
    userAdmin.calendars?.[CALENDARS.SALES]?.map((item: any) => item.name) || [];

  const handleUpdateRow = () => {
    const newUpdate = {
      status: "Colloquio fissato",
      updates: JSON.stringify([
        ...JSON.parse(user.updates),
        { status: "Colloquio fissato", date: null, admin },
      ]),
    };

    callUpdateCrm.mutate({
      id: user.id,
      data: newUpdate,
      callback: () => setOpenCalendar(false),
    });
  };

  const handleChange = ({ target: { name, value } }) => {
    setForm((p: any) => ({ ...p, [name]: value }));
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    const owned_by = calendars.includes(admin) ? admin : null;

    let phone = form.phone?.replace(/[\s\-\[\]_]/g, "");
    if (phone && !phone.startsWith("+")) {
      const phoneNumber = parsePhoneNumberFromString(phone, "IT");
      if (phoneNumber) {
        phone = phoneNumber.formatInternational();
      } else {
        phone = `+IT${phone}`;
      }
    }

    let data: any = {
      ...form,
      owned_by,
      phone: phone,
      status: "Nuovo",
      save_utm: true,
      utm_source: form.from,
      created_by_admin: userAdmin.user.id,
      updates: JSON.stringify([
        {
          status: "Nuovo",
          date: null,
          admin,
          owned_by,
        },
      ]),
    };

    if (form.from == "WhatsApp") {
      data = {
        ...data,
        utm_ads: "WhatsApp",
        utm_source: "WhatsApp",
        utm_medium: "WhatsApp",
        utm_content: "WhatsApp",
        utm_campaign: "WhatsApp",
        details: JSON.stringify([
          { label: "offer", value: "WhatsApp" },
          { label: "utm_source", value: "WhatsApp" },
          { label: "utm_medium", value: "WhatsApp" },
          { label: "utm_campaign", value: "WhatsApp" },
          { label: "utm_content", value: "WhatsApp" },
          { label: "utm_ads", value: "WhatsApp" },
          { label: "utm_term", value: "WhatsApp" },
        ]),
      };
    }

    let res: any = null;

    try {
      res = await useAPI("/v1/crm", data);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      snackHandler(String(error), "error");
      return;
    }

    setIsLoading(false);

    if (res?.status == false) {
      snackHandler(res?.message || "Failed create lead!", "error");
      return;
    }

    setUser(res);
    setForm({ tutoring: Number(isAdmission) });
    handleClose();
    setOpenCalendar(true);
    queryClient.invalidateQueries([keyCache, getParams()]);
  };

  useEffect(() => {
    setForm((p: INForm) => ({ ...p, tutoring: Number(isAdmission) }));
  }, [isAdmission]);

  return (
    <>
      <EduModal
        fullWidth
        open={open}
        onClose={handleClose}
        maxWidth="sm"
      >
        <EduModalTitle onClose={handleClose}>
          <img src={addIcon} />
          ADD MANUALLY {isAdmission && " tutoring"}
        </EduModalTitle>

        <EduModalBody
          onSubmit={submitForm}
          component="form"
          className="flex flex-col gap-5 p-6 pt-8"
        >
          <Box className="flex gap-5">
            <TextField
              required
              fullWidth
              name="first_name"
              label="First name"
              value={form?.first_name ?? ""}
              onChange={handleChange}
            />

            <TextField
              required
              fullWidth
              name="last_name"
              label="Last name"
              value={form?.last_name ?? ""}
              onChange={handleChange}
            />
          </Box>

          <TextField
            required
            fullWidth
            name="email"
            label="Email"
            value={form?.email ?? ""}
            onChange={handleChange}
          />

          <PhoneField
            fullWidth
            name="phone"
            label="Phone"
            value={form?.phone ?? ""}
            onChange={(phone: string) => setForm((p) => ({ ...p, phone }))}
          />

          <Box className="flex gap-5">
            <TextField
              select
              fullWidth
              name="from"
              label="Utm_source"
              value={form?.from ?? ""}
              onChange={handleChange}
            >
              {["WhatsApp", "Email", "IG DM", "Mail Marketing", "Referral"].map(
                (e, i) => (
                  <MenuItem
                    key={i}
                    value={e}
                  >
                    {e}
                  </MenuItem>
                )
              )}
            </TextField>

            <TextField
              select
              required
              fullWidth
              name="lang"
              label="Language"
              value={form?.lang || ""}
              onChange={handleChange}
            >
              {LANGUAGES.map((lang) => (
                <MenuItem
                  key={lang.iso}
                  value={lang.iso}
                >
                  <Box className="flex">
                    <span className="w-8">{lang.iso.toUpperCase()}</span>
                    <span>{lang.name}</span>
                  </Box>
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box className="flex flex-row-reverse">
            <LoadingButton
              type="submit"
              variant="outlined"
              loading={isLoading}
            >
              <img
                src={saveIcon}
                className="icon"
              />
              Save
            </LoadingButton>
          </Box>
        </EduModalBody>
      </EduModal>

      <Drawer
        sx={{ zIndex: 1300 }}
        open={openCalendar}
        anchor="right"
        onClose={() => setOpenCalendar(false)}
      >
        {user ? (
          <CalendarEvents
            user={user}
            status="Colloquio fissato"
            onUpdateRow={handleUpdateRow}
          />
        ) : (
          "No user selected"
        )}
      </Drawer>
    </>
  );
});

export default CreateNewUserDialog;
