import client from "@/api/client";
import saveIcon from "@/assets/icons/save.svg";
import { PriceCheckIcon } from "@/components";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Tooltip,
  useTheme,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "../_Common/Modal/ModalStyles";
import MONTHS from "../assets/MONTHS";
import { types } from "../PaymentsIn/CreateInvoicePaymentInDialog";
import { formatPrice } from "../useHelpers";

export interface IPaymentData {
  date?: Date | string;
  type?: string;
  amount?: number | string;
  paidFor?: string;
  quantity?: string;
  isInitial?: boolean;
  start_month?: string;
  isPaidTotal?: boolean;
  tokenProduct?: string;
  enable_quantity?: boolean;
}

export interface INData {
  user: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    meeting: any;
  };
  products: {
    id: number;
    token: string;
    price: number | string;
    product_tag: string;
    enable_quantity: boolean | undefined;
  }[];
  isLoading?: boolean;
}

interface IDefaultValues {
  amount?: string;
  paidFor?: string;
  pacchetto?: string;
  editStartMonthAfterAttach?: boolean;
}

interface INProps {
  open: boolean;
  data: INData;
  onClose: () => void;
  onSubmit: (...params: any) => void;
  isUpselling?: boolean;
  defaultValues?: IDefaultValues;
}

export type IAddressData = {
  zipcode?: string;
  city?: string;
  address?: string;
  country?: string;
};

const ConfirmPaymentDialog = ({
  open,
  data,
  onClose,
  onSubmit,
  isUpselling,
  defaultValues,
}: INProps) => {
  const theme = useTheme();

  const isInitialLoad = useRef(true);

  const [addressData, setAddressData] = useState<IAddressData | null>(null);
  const [paymentData, setPaymentData] = useState<IPaymentData>({
    type: "Bonifico",
    date: dayjs().format("YYYY-MM-DD"),
    amount: defaultValues?.amount,
    paidFor: defaultValues?.paidFor,
    isInitial: false,
    isPaidTotal: false,
    start_month: MONTHS[dayjs().format("MMMM")] + " " + dayjs().format("YYYY"),
    tokenProduct: defaultValues?.pacchetto,
    enable_quantity: false,
  });

  const { data: countries } = useQuery(["countries-list"], () =>
    client.countries_list({
      key_value: {
        key: "en",
        value: "it",
      },
      columns: ["prefix"],
    })
  );

  const startMonthFuture = useMemo(() => {
    const dates: Array<{ en: string; it: string }> = [];

    let date = dayjs();

    for (let i = 0; i < 12; i++) {
      const mEN = date.format("MMMM");
      const y = date.format("YYYY");
      dates.push({
        en: mEN + " " + y,
        it: MONTHS[mEN] + " " + y,
      });
      date = date.add(1, "M");
    }

    return dates.map((d) => (
      <MenuItem
        key={d.en}
        value={d.it}
      >
        {d.en}
      </MenuItem>
    ));
  }, []);

  useEffect(() => {
    if (isInitialLoad.current && defaultValues?.pacchetto) {
      const pacchettoDefault = data?.products?.find(
        (p: any) => p.token === defaultValues.pacchetto
      );

      if (pacchettoDefault) {
        setPaymentData((p: any) => ({
          ...p,
          amount: defaultValues?.amount,
          paidFor: defaultValues?.paidFor,
          tokenProduct: defaultValues?.pacchetto,
          enable_quantity: pacchettoDefault.enable_quantity,
        }));
      }

      isInitialLoad.current = false;
    }
  }, [defaultValues, data.products]);

  useEffect(() => {
    if (!countries || !data?.user?.phone) return;

    const country = countries?.find((item) =>
      data?.user?.phone?.startsWith(item?.prefix)
    )?.value;

    if (typeof country === "string") {
      setAddressData((prev) => ({
        ...prev,
        country: country,
      }));
    }
  }, [data?.user?.phone, countries]);

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={onClose}>
        <Box className="flex items-center gap-1">
          <PriceCheckIcon fontSize="small" />
          Do you confirm that {data.user.first_name} {data.user.last_name} has
          paid?
        </Box>
      </EduModalTitle>

      <EduModalBody>
        <Box
          onSubmit={(e) => {
            e.preventDefault();
            onSubmit(data.user, { ...paymentData, ...addressData });
          }}
          component="form"
          className="flex flex-col gap-5 m-5"
        >
          {!isUpselling && (
            <Box className="grid grid-cols-2 gap-5">
              <FormControlLabel
                sx={{
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
                label="Is initial payment"
                control={
                  <Checkbox
                    name="isInitial"
                    checked={paymentData.isInitial}
                    onChange={(e) =>
                      setPaymentData((p: any) => ({
                        ...p,
                        isInitial: e.target.checked,
                      }))
                    }
                  />
                }
                className="rounded w-full !ml-0 select-none"
              />

              <FormControlLabel
                sx={{
                  color: theme.palette.primary.main,
                  border: `1px solid ${theme.palette.primary.main}`,
                }}
                label="Is paid total"
                control={
                  <Checkbox
                    name="isPaidTotal"
                    checked={paymentData.isPaidTotal}
                    onChange={(e) =>
                      setPaymentData((p: any) => ({
                        ...p,
                        isPaidTotal: e.target.checked,
                      }))
                    }
                  />
                }
                className="rounded w-full !ml-0 select-none"
              />
            </Box>
          )}

          <TextField
            select
            required
            fullWidth
            name="tokenProduct"
            label="Product"
            value={paymentData?.tokenProduct ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const pacchetto: any = data?.products?.find(
                (p: any) => p.token === e.target.value
              );

              setPaymentData((p: any) => ({
                ...p,
                amount: p.amount || String(pacchetto.price / 100),
                paidFor: pacchetto.product_tag,
                quantity: "1",
                tokenProduct: pacchetto.token,
                enable_quantity: pacchetto.enable_quantity,
              }));
            }}
          >
            {data.products?.map((product: any) => (
              <MenuItem
                key={product.id}
                value={product.token}
              >
                <Tooltip title={product.product_tag}>
                  <Box className="flex">
                    <Box className="truncate mr-2">{product.product_tag}</Box>
                    {"€" + (product?.price / 100).toFixed(2)}
                  </Box>
                </Tooltip>
              </MenuItem>
            ))}
          </TextField>

          <TextField
            required
            fullWidth
            type="text"
            name="paidFor"
            label="Paid for"
            value={paymentData?.paidFor ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setPaymentData((p: any) => ({
                ...p,
                paidFor: e.target.value,
              }))
            }
          />

          <Box className="grid grid-cols-2 gap-5">
            <TextField
              required
              fullWidth
              name="amount"
              type="number"
              label="Amount"
              value={paymentData?.amount ?? ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setPaymentData((p: any) => ({
                  ...p,
                  amount: formatPrice(e.target.value),
                }));
              }}
            />

            <TextField
              select
              required
              fullWidth
              name="start_month"
              label="Start month"
              value={paymentData?.start_month ?? ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPaymentData((p: any) => ({
                  ...p,
                  start_month: e.target.value,
                }))
              }
            >
              {startMonthFuture}
            </TextField>
          </Box>

          {paymentData?.enable_quantity && (
            <TextField
              required
              name="quantity"
              type="number"
              label="Quantity"
              value={paymentData?.quantity ?? ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPaymentData((p: any) => ({
                  ...p,
                  quantity: e.target.value,
                }))
              }
            />
          )}

          <Box className="grid grid-cols-2 gap-5">
            <TextField
              required
              fullWidth
              name="date"
              type="date"
              label="Date"
              value={paymentData?.date ?? dayjs().format("YYYY-MM-DD")}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPaymentData((p: any) => ({ ...p, date: e.target.value }))
              }
            />

            <TextField
              select
              required
              fullWidth
              name="type"
              label="Type"
              value={paymentData?.type ?? ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setPaymentData((p: any) => ({
                  ...p,
                  type: e.target.value,
                }))
              }
            >
              {types.map((type) => (
                <MenuItem
                  key={type}
                  value={type}
                >
                  {type}
                </MenuItem>
              ))}
            </TextField>
          </Box>

          <Box className="grid grid-cols-2 gap-5">
            <TextField
              required
              fullWidth
              select
              name="country"
              label="Country"
              value={addressData?.country || ""}
              onChange={(e) =>
                setAddressData((prev) => ({
                  ...prev,
                  country: e.target.value,
                }))
              }
            >
              {countries?.map((item, i) => (
                <MenuItem
                  key={item?.key + i}
                  value={item?.value}
                >
                  {item?.key}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              required
              fullWidth
              name="address"
              label="Address"
              value={addressData?.address}
              onChange={(e) =>
                setAddressData((p) => ({
                  ...p,
                  address: e.target.value,
                }))
              }
              error={
                addressData?.address && addressData?.address?.length < 6
                  ? true
                  : false
              }
              helperText={
                addressData?.address &&
                addressData?.address?.length < 6 &&
                "Address is too short"
              }
            />
          </Box>

          <Box className="grid grid-cols-2 gap-5">
            <TextField
              required
              name="city"
              label="City"
              value={addressData?.city}
              onChange={(e) =>
                setAddressData((p) => ({
                  ...p,
                  city: e.target.value,
                }))
              }
              error={
                addressData?.city && addressData?.city?.length < 3
                  ? true
                  : false
              }
              helperText={
                addressData?.city &&
                addressData?.city?.length < 3 &&
                "City is too short"
              }
            />

            <TextField
              required
              name="zipcode"
              label="Postal code"
              value={addressData?.zipcode}
              onChange={(e) =>
                setAddressData((p) => ({
                  ...p,
                  zipcode: e.target.value,
                }))
              }
              error={
                addressData?.zipcode && addressData?.zipcode?.length < 3
                  ? true
                  : false
              }
              helperText={
                addressData?.zipcode &&
                addressData?.zipcode?.length < 3 &&
                "Postal code is too short"
              }
            />
          </Box>

          <Box className="flex flex-row-reverse">
            <LoadingButton
              type="submit"
              color="primary"
              variant="outlined"
              loading={data?.isLoading}
            >
              <img
                src={saveIcon}
                className={`icon ${data?.isLoading ? "opacity-0" : ""}`}
              />
              Save
            </LoadingButton>
          </Box>
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default ConfirmPaymentDialog;
