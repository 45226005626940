import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useAuth from "@/hooks/useAuth";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import {
  Box,
  Button,
  useTheme,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import dayjs from "dayjs";
import addIcon from "@/assets/icons/add.svg";
import saveIcon from "@/assets/icons/save.svg";
import useCrmApi from "@/api/useCrmApi";
import deleteIcon from "@/assets/icons/delete.svg";
import ConfirmationDialog from "../ConfirmationDialog";
import { convert } from "../useHelpers";

export interface IForwardRef {
  open: (user: any) => void;
}

interface IProps {
  onClose?: () => void;
}

export interface IComments {
  admin: {
    name: string;
    lname: string;
    email: string;
  };
  created: string;
  content: string;
  created_at: string;
  isFromMeeting?: string;
}

export const sortDescNotesByCreatedAt = (
  v: Array<IComments>
): Array<IComments> =>
  v.sort(
    (a: any, b: any) =>
      dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf()
  );

const CommentDialog = forwardRef<IForwardRef, IProps>(({ onClose }, ref) => {
  const theme = useTheme();
  const { userAdmin } = useAuth();
  const { callUpdateCrm } = useCrmApi();

  const [user, setUser] = useState<any>();
  const [notes, setNotes] = useState<Array<any>>([]);
  const [addNote, setAddNote] = useState(false);
  const [content, setContent] = useState<string>("");
  const [openConfirm, setOpenConfirm] = useState(false);
  const [commentIndex, setCommentIndex] = useState<number>();
  const [notesMeetings, setNotesMeetings] = useState<Array<any>>([]);

  useImperativeHandle(ref, () => ({
    open: (user: any) => setUser(user),
  }));

  const submitForm = async () => {
    const { id, first_name, last_name, email } = userAdmin.user;

    const newNote = {
      admin: { id, name: first_name, lname: last_name, email },
      created: dayjs().format("DD-MM") + " at " + dayjs().format("HH:mm"),
      created_at: dayjs().format("YYYY-MM-DD HH:mm"),
      content: content,
    };

    try {
      const newUpdate = {
        notes: [
          ...(notes?.filter(
            (n) => !n?.isFromMeeting || n?.isFromMeeting != "from meeting"
          ) || []),
          newNote,
        ],
      };

      callUpdateCrm.mutate({
        id: user.id,
        data: newUpdate,
        callback: () => {
          setContent("");
          setAddNote(false);
          setNotes(
            sortDescNotesByCreatedAt([...newUpdate.notes, ...notesMeetings])
          );
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteComment = () => {
    setOpenConfirm(false);

    const newUpdate = {
      notes:
        notes.filter(
          (n, i) =>
            i !== commentIndex &&
            (!n?.isFromMeeting || n?.isFromMeeting != "from meeting")
        ) || [],
    };

    callUpdateCrm.mutate({
      id: user.id,
      data: newUpdate,
      callback: () => {
        setNotes(
          sortDescNotesByCreatedAt([...newUpdate.notes, ...notesMeetings])
        );
      },
    });
  };

  const handleClose = () => {
    setCommentIndex(undefined);
    if (!content && addNote) setAddNote(false);
    setUser(undefined);
    setNotes([]);
    setOpenConfirm(false);
    setNotesMeetings([]);
    onClose?.();
  };

  useEffect(() => {
    let notesUser: Array<any> = user?.notes ? convert(user?.notes) : [];

    const meetingsUser = user?.meeting ? convert(user?.meeting) : {};

    const meets =
      meetingsUser?.meet
        ?.filter((e: any) => Boolean(e?.notes))
        .map((e: any) => {
          return {
            admin: {
              name: e?.interviewer,
              lname: "",
              email: "",
            },
            created: dayjs(e?.start).format("DD-MM % HH:mm").replace("%", "at"),
            content: e?.notes,
            created_at: e?.start,
            isFromMeeting: "from meeting",
          };
        }) ?? [];

    setNotesMeetings(meets);

    setNotes(sortDescNotesByCreatedAt([...notesUser, ...meets]));
  }, [user, user?.notes, user?.meeting]);

  return (
    <>
      <EduModal
        fullWidth
        open={Boolean(user)}
        onClose={handleClose}
        maxWidth="sm"
      >
        <EduModalTitle onClose={handleClose}>
          <img src={addIcon} />
          Comments
        </EduModalTitle>

        <EduModalBody className="p-5">
          <Box className="flex flex-col gap-4">
            {notes.length > 0 ? (
              notes?.map((e: any, i: number) => (
                <Box key={i}>
                  <Box className="mb-2 flex justify-between items-center">
                    <Typography
                      color="primary"
                      fontSize="10px"
                      className={e?.isFromMeeting ? "pb-2 pt-1" : ""}
                    >
                      {e?.admin.name} {e?.admin.lname} wrote on {e?.created}{" "}
                      {e?.isFromMeeting}
                    </Typography>

                    {!e?.isFromMeeting && (
                      <Button
                        title="Delete Note"
                        variant="outlined"
                        onClick={() => {
                          setCommentIndex(i);
                          setOpenConfirm(true);
                        }}
                      >
                        <img
                          src={deleteIcon}
                          className="icon no-margin"
                        />
                      </Button>
                    )}
                  </Box>

                  <Box
                    sx={{
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.primary.main,
                    }}
                    className="flex flex-col border rounded mt-1"
                  >
                    <Typography className="p-2">{e?.content}</Typography>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography
                sx={{ textAlign: "center", color: "#B4B4B4" }}
                fontSize={16}
              >
                No comments here, add the first!
              </Typography>
            )}
          </Box>

          <Box className="flex gap-2 flex-col mt-5">
            {addNote ? (
              <>
                <Box className="flex gap-4 items-center justify-between grow">
                  <Box className="text-lg">New note</Box>
                </Box>

                <TextareaAutosize
                  value={content}
                  minRows={5}
                  onChange={(e) => setContent(e.target.value)}
                  placeholder="Write here..."
                />

                <Box className="flex justify-end">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={submitForm}
                    disabled={!content}
                  >
                    <img
                      src={saveIcon}
                      className="icon"
                    />
                    Save
                  </Button>
                </Box>
              </>
            ) : (
              <Box className="flex justify-end">
                <Button
                  variant="outlined"
                  onClick={() => setAddNote(true)}
                >
                  <img
                    src={addIcon}
                    className="icon"
                  />
                  Add note
                </Button>
              </Box>
            )}
          </Box>
        </EduModalBody>
      </EduModal>

      <ConfirmationDialog
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={handleDeleteComment}
      >
        Do you want to delete comment?
      </ConfirmationDialog>
    </>
  );
});

export default CommentDialog;
