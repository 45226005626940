import { memo } from "react";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import { Box, Typography, useTheme } from "@mui/material";
import feedbackIcon from "@/assets/icons/feedback.svg";

interface ITutorReport {
  id: number;
  recommended_level: string;
  reason: string | null;
  created_at: string;
  excels_in: string | null;
  improvement: string | null;
  suggestions: string | null;
  user: {
    id: number;
    first_name: string;
    last_name: string;
    english_level: string;
  };
}

interface IProps {
  open: boolean;
  onClose: () => void;
  reports: ITutorReport[];
}

const TutorReportsModal = ({ open, onClose, reports }: IProps) => {
  const { palette } = useTheme();

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  return (
    <EduModal
      fullWidth
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <EduModalTitle onClose={onClose}>
        <img
          src={feedbackIcon}
          className="icon"
          alt="Report Icon"
        />
        Tutor Reports
      </EduModalTitle>

      <EduModalBody className="p-4">
        <Box className="flex flex-col gap-4">
          {reports && reports.length > 0 ? (
            reports.map((report) => (
              <Box
                key={report.id}
                className="border rounded p-4"
                sx={{ borderColor: palette.primary.main }}
              >
                <Box className="grid grid-cols-3 gap-4 mb-4">
                  <Box>
                    <Box>
                      <Typography
                        color="text.secondary"
                        className="font-medium mb-1"
                      >
                        Current Level:
                      </Typography>
                      <Typography
                        color={palette.primary.main}
                        className="font-bold"
                      >
                        {report?.user?.english_level}
                      </Typography>
                    </Box>
                    <Typography
                      color="text.secondary"
                      className="font-medium mb-1"
                    >
                      Recommended Level:
                    </Typography>
                    <Typography
                      color={palette.primary.main}
                      className="font-bold"
                    >
                      {report?.recommended_level}
                    </Typography>
                  </Box>

                  {report?.user && (
                    <Box>
                      <Typography
                        color="text.secondary"
                        className="font-medium mb-1"
                      >
                        User:
                      </Typography>
                      <Typography>
                        {report?.user?.first_name +
                          " " +
                          report?.user?.last_name}
                      </Typography>
                    </Box>
                  )}

                  <Box>
                    <Typography
                      color="text.secondary"
                      className="font-medium mb-1"
                    >
                      Date:
                    </Typography>
                    <Typography>{formatDate(report?.created_at)}</Typography>
                  </Box>
                </Box>

                {report.reason && (
                  <Box>
                    <Typography
                      color="text.secondary"
                      className="font-medium mb-1"
                    >
                      Reason:
                    </Typography>
                    <Typography>{report.reason}</Typography>
                  </Box>
                )}
              </Box>
            ))
          ) : (
            <Typography
              sx={{ textAlign: "center", color: "#B4B4B4" }}
              fontSize={16}
            >
              No tutor reports available
            </Typography>
          )}
        </Box>
      </EduModalBody>
    </EduModal>
  );
};

export default memo(TutorReportsModal);
