import { createNewUser } from "@/api";
import client from "@/api/client";
import useAPI from "@/api/useAPI";
import useUserApi from "@/api/useUserApi";
import addIcon from "@/assets/icons/add.svg";
import editIcon from "@/assets/icons/edit.svg";
import saveIcon from "@/assets/icons/save.svg";
import PhoneField from "@/components/_Common/Forms/PhoneField";
import {
  EduModal,
  EduModalBody,
  EduModalTitle,
} from "@/components/_Common/Modal/ModalStyles";
import LANGUAGES from "@/components/assets/LANGUAGES";
import useAuth from "@/hooks/useAuth";
import { queryClient } from "@/main";
import "@/utils";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { ENGLISH_LEVEL, MONTH_LIST } from "../UserSingle";

export const getWithNewCountries = (
  countries: Array<{
    key: string;
    prefix: string;
    value: string;
  }>
) => [
  ...(countries ?? []),
  {
    key: "deutschland",
    prefix: "+49",
    value: "Deutschland",
  },
  {
    key: "schweiz",
    prefix: "+49",
    value: "Schweiz",
  },
];

export interface Products {
  id: string;
  name: string;
}

interface CreateUserProps {
  user: any;
  open: boolean;
  cacheKey: any;
  products: Products[];
  handleOpen?: () => void;
  handleClose: () => void;
}

const CreateUser: FC<CreateUserProps> = ({
  user,
  open,
  products,
  cacheKey,
  handleClose,
}) => {
  const { callUpdateUser } = useUserApi();
  const { userAdmin, isAdmission, snackHandler } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [checkedBeta, setCheckedBeta] = useState<boolean>(
    Boolean(user?.settings?.isBeta)
  );
  const [startingMonths, setStartingMonths] = useState(
    [] as { label: string; value: string }[]
  );

  const { data: countries } = useQuery(["countries-list"], () =>
    client.countries_list({
      key_value: {
        key: "en",
        value: "it",
      },
      columns: ["prefix"],
    })
  );

  const formik = useFormik({
    initialValues: {
      zipcode: "",
      phone: "",
      lang: "",
      first_name: "",
      admin: 0,
      email: "",
      city: "",
      isBeta: 0,
      last_name: "",
      product: "",
      address: "",
      country: "",
      hours_done: 0,
      start_month: "",
      english_level: "",
      additional_credits: 0,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Required").min(3, "min 3 char"),
      email: Yup.string().email("Invalid email address").required("Required"),
      last_name: Yup.string().required("Required").min(3, "min 3 char"),
      phone: Yup.string()
        .matches(
          /^(\+\d{2,3})?\d{7,10}$/,
          "Must be a valid phone number with prefix and digits"
        )
        .required("Required"),
      ...(isAdmission
        ? {
            hours_done: Yup.string().required("Required"),
          }
        : {
            start_month: Yup.string().required("Required"),
            english_level: Yup.string().required("Required"),
            admin: Yup.boolean(),
          }),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);

      if (!user && !isAdmission) {
        const { errors } = await createNewUser({
          tk: userAdmin.token,
          params: values,
        });

        setIsLoading(false);

        queryClient.invalidateQueries(cacheKey);

        if (errors) {
          formik.setErrors(errors);
          return;
        }
      } else {
        const userToUpdate: any = {
          zipcode: values.zipcode,
          phone: values.phone,
          lang: values.lang,
          first_name: values?.first_name,
          email: values.email,
          city: values.city,
          last_name: values?.last_name,
          address: values.address,
          country: values.country,
          additional_credits: values.additional_credits,
          ...(isAdmission
            ? {
                hours_done: values.hours_done,
              }
            : {
                start_month: values.start_month,
                english_level: values.english_level,
              }),
        };

        if (typeof values?.product == "number") {
          userToUpdate.product = values.product;
        }

        if (isAdmission) {
          await useAPI("/v1/tutoring/update/" + user.token, userToUpdate);
        } else {
          await callUpdateUser.mutate({
            id: user.token,
            data: userToUpdate,
            callback() {},
          });
        }
      }

      queryClient.invalidateQueries(cacheKey);
      setIsLoading(false);
      formik.resetForm();
      handleClose();
    },
  });

  const handleActiveBeta = async (checked: boolean) => {
    setCheckedBeta(checked);

    const res = await useAPI("/v1/users/set-active-beta", {
      isBeta: Number(checked),
      token_user: user.token,
    });

    if (!res?.status) {
      snackHandler(res?.message ?? "Failed update beta user!", "error");
      setCheckedBeta(!checked);
      return;
    }

    snackHandler("Success " + (checked ? "active" : "disable") + " beta user!");
    setCheckedBeta(Boolean(res?.user?.settings?.isBeta));
  };

  useEffect(() => {
    if (isAdmission) return;

    const currentDate = dayjs();

    const months: { label: string; value: string }[] = [];

    // Generate the list of months for the next 6 months
    for (let i = 0; i < 12; i++) {
      // Create a regexp with the month names to replace
      const regexp = new RegExp(Object.keys(MONTH_LIST).join("|"), "g");

      //BEFORE
      const monthBefore = currentDate.subtract(i + 1, "month");
      const enBefore = monthBefore.format("MMMM YYYY");

      // Translate the month name
      const itBefore = enBefore.replace(regexp, function (x) {
        return MONTH_LIST[x];
      });

      months.unshift({
        label: enBefore,
        value: itBefore,
      });

      if (i < 8) {
        // AFTER
        const monthAfter = currentDate.add(i, "month");
        const enAfter = monthAfter.format("MMMM YYYY");

        // Translate the month name
        const itAfter = enAfter.replace(regexp, function (x) {
          return MONTH_LIST[x];
        });

        months.push({
          label: enAfter,
          value: itAfter,
        });
      }
    }

    const never: { label: string; value: string } = {
      label: "NEVER",
      value: "NEVER",
    };

    const noReply: { label: string; value: string } = {
      label: "NO REPLY",
      value: "NO REPLY",
    };

    months.unshift(noReply);
    months.unshift(never);

    setStartingMonths(months);
  }, []);

  useEffect(() => {
    if (!user?.id) return;

    const u = { ...user };

    if (typeof user?.products?.at(-1)?.id == "number") {
      u.product = user?.products?.at(-1)?.id;
    }

    formik.setValues(u);
  }, [user?.id]);

  useEffect(() => {
    setCheckedBeta(Boolean(user?.settings?.isBeta));
  }, [user?.settings]);

  const hasUserOrIsAdmission = user || isAdmission;

  return (
    <>
      <EduModal
        fullWidth
        open={open}
        onClose={() => {
          handleClose();
          formik.resetForm();
          setIsLoading(false);
        }}
        maxWidth="sm"
      >
        <EduModalTitle
          onClose={() => {
            handleClose();
            formik.resetForm();
          }}
        >
          <img
            src={hasUserOrIsAdmission ? editIcon : addIcon}
            className="icon"
          />
          {hasUserOrIsAdmission ? "Edit user" : "Create new user"}
        </EduModalTitle>

        <EduModalBody className="flex flex-col p-5 pt-6">
          <Box
            onSubmit={formik.handleSubmit}
            component="form"
            className="flex flex-col gap-5"
          >
            <Box className="flex gap-5">
              <Box className="md:w-1/2">
                <TextField
                  fullWidth
                  name="first_name"
                  label="First name"
                  value={formik?.values.first_name}
                  error={
                    formik.touched.first_name &&
                    Boolean(formik.errors.first_name)
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "first_name",
                      e.target.value.strCapitalization()
                    )
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                />
              </Box>

              <Box className="md:w-1/2">
                <TextField
                  fullWidth
                  name="last_name"
                  label="Last name"
                  value={formik.values.last_name}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  onChange={(e) =>
                    formik.setFieldValue(
                      "last_name",
                      e.target.value.strCapitalization()
                    )
                  }
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                />
              </Box>
            </Box>

            <Box className="flex gap-5">
              <TextField
                name="email"
                label="Email"
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                className="md:w-1/2"
                onChange={formik.handleChange}
                helperText={formik.touched.email && formik.errors.email}
              />

              <PhoneField
                name="phone"
                label="Phone"
                value={formik.values.phone}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                onChange={(value: string) =>
                  formik.setFieldValue("phone", value.replaceAll(" ", ""))
                }
                className="md:w-1/2"
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Box>

            <Box className="flex gap-5">
              {!isAdmission && (
                <>
                  <FormControl className="md:w-1/2">
                    <InputLabel id="demo-simple-select-label">
                      English level
                    </InputLabel>
                    <Select
                      id="demo-simple-select"
                      name="english_level"
                      label="English level"
                      error={
                        formik.touched.english_level &&
                        Boolean(formik.errors.english_level)
                      }
                      value={formik.values.english_level}
                      labelId="demo-simple-select-label"
                      onChange={formik.handleChange}
                      defaultValue={formik.values.english_level}
                    >
                      {ENGLISH_LEVEL.map((e, i) => (
                        <MenuItem
                          key={e + i}
                          value={e}
                        >
                          {e}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl className="md:w-1/2">
                    <InputLabel id="demo-simple-select-label2">
                      Start month
                    </InputLabel>
                    <Select
                      id="demo-simple-select2"
                      name="start_month"
                      label="Start month"
                      error={
                        formik.touched.start_month &&
                        Boolean(formik.errors.start_month)
                      }
                      value={formik.values.start_month}
                      labelId="demo-simple-select-label2"
                      onChange={formik.handleChange}
                      defaultValue={formik.values.start_month}
                    >
                      {startingMonths?.map((e, i) => (
                        <MenuItem
                          key={e.label + i}
                          value={e.value}
                        >
                          {e.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>
              )}

              {isAdmission && (
                <FormControl className="md:w-1/2">
                  <TextField
                    id="demo-simple-select2"
                    name="hours_done"
                    label="Hours done"
                    value={formik.values.hours_done}
                    error={
                      formik.touched.hours_done &&
                      Boolean(formik.errors.hours_done)
                    }
                    onChange={formik.handleChange}
                    className="!mr-3"
                  />
                </FormControl>
              )}
            </Box>

            <Box className="flex gap-5">
              {!isAdmission && (
                <FormControl
                  fullWidth
                  className={!user ? "md:w-1/2" : ""}
                >
                  <InputLabel id="demo-simple-select-label3">
                    Product
                  </InputLabel>
                  <Select
                    id="demo-simple-select3"
                    name="product"
                    label="Product"
                    error={
                      formik.touched.product && Boolean(formik.errors.product)
                    }
                    value={
                      formik.values.product || user?.products?.at(-1)?.id || ""
                    }
                    labelId="demo-simple-select-label3"
                    onChange={formik.handleChange}
                    defaultValue={
                      formik.values.product || user?.products?.at(-1)?.id || ""
                    }
                  >
                    {products?.map((e, i) => (
                      <MenuItem
                        key={e.name + i}
                        value={e.id}
                      >
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {!user && !isAdmission && (
                <FormControl
                  fullWidth
                  className="md:w-1/2"
                >
                  <InputLabel id="demo-simple-select-label2">Admin</InputLabel>
                  <Select
                    id="demo-simple-select2"
                    name="admin"
                    label="Admin"
                    value={formik.values.admin}
                    error={formik.touched.admin && Boolean(formik.errors.admin)}
                    labelId="demo-simple-select-label2"
                    onChange={formik.handleChange}
                    defaultValue={formik.values.admin}
                  >
                    {[
                      {
                        value: 1,
                        label: "Yes",
                      },
                      {
                        value: 0,
                        label: "No",
                      },
                    ].map((e) => (
                      <MenuItem
                        key={e.value}
                        value={e.value}
                      >
                        {e.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Box>

            <Box className="grid grid-cols-2 gap-4">
              <TextField
                required
                fullWidth
                type="number"
                name="additional_credits"
                label="Add/Remove credits"
                value={
                  String(Number(formik.values.additional_credits ?? 0)) || 0
                }
                onChange={formik.handleChange}
              />

              <TextField
                select
                fullWidth
                name="lang"
                label="Language"
                value={formik.values?.lang}
                onChange={formik.handleChange}
              >
                {LANGUAGES.map((lang) => (
                  <MenuItem
                    key={lang.iso}
                    value={lang.iso}
                  >
                    <Box className="flex">
                      <span className="w-8">{lang.iso.toUpperCase()}</span>
                      <span>{lang.name}</span>
                    </Box>
                  </MenuItem>
                ))}
              </TextField>
            </Box>

            <Box className="grid grid-cols-2 gap-5">
              <TextField
                required
                fullWidth
                select
                name="country"
                label="Country"
                value={formik.values?.country ?? ""}
                onChange={formik.handleChange}
              >
                {getWithNewCountries(countries)?.map((item, i) => (
                  <MenuItem
                    key={item?.key + i}
                    value={item?.value}
                  >
                    {item?.key}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                name="address"
                label="Address"
                value={formik.values?.address}
                onChange={formik.handleChange}
              />
            </Box>

            <Box className="grid grid-cols-2 gap-4">
              <TextField
                name="city"
                label="City"
                value={formik.values?.city}
                onChange={formik.handleChange}
              />

              <TextField
                name="zipcode"
                label="Postal code"
                value={formik.values?.zipcode}
                onChange={formik.handleChange}
              />
            </Box>

            <Box className="flex justify-between">
              <FormControlLabel
                sx={{ pl: "0.75rem" }}
                label={(checkedBeta ? "Disable" : "Enable") + " academy BETA"}
                control={
                  <Switch
                    name="isBeta"
                    checked={checkedBeta}
                    onChange={({ target: { checked } }) =>
                      handleActiveBeta(Boolean(checked))
                    }
                  />
                }
              />

              <LoadingButton
                type="submit"
                variant="outlined"
                loading={isLoading}
              >
                <img
                  src={saveIcon}
                  className="icon"
                />
                Save
              </LoadingButton>
            </Box>
          </Box>
        </EduModalBody>
      </EduModal>
    </>
  );
};

export default CreateUser;
